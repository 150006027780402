import dayjs from 'dayjs';
import { NULLABLE_VALUE } from 'ui-component/RHFFormField';

// Calculate the total amount of securities based on shares and share price
export const CommonTotalAmount = (shares, sharePrice) => {
  return shares * sharePrice;
};

// Calculate the end date of a vacation given a start date and duration in weeks
export const VacationEndDate = (startDate, duration) => {
  let endDate,
    calcDate = dayjs(startDate, 'MM/YYYY');
  if (calcDate.isValid()) {
    endDate = calcDate;
    if (duration) {
      endDate = calcDate.add(duration, 'week');
    }
  }
  return endDate;
};

// Calculate the remaining term of a loan in years given monthly payment, interest rate, and account balance
export const LoanRemainingTerm = (monthlyPayment, interestRate, accountBalance) => {
  // Assuming that Interest Rate is Annually applied
  const monthlyInterestRate = interestRate / 100 / 12;

  const numerator = Math.log(monthlyPayment / (monthlyPayment - monthlyInterestRate * accountBalance));
  const denominator = Math.log(1 + monthlyInterestRate);
  const remainingTermMonths = numerator / denominator;
  // Rounding off
  const remainingTermYears = Math.round((remainingTermMonths / 12) * 100) / 100;
  return remainingTermYears;
};

// Calculate the sum of start date and planning horizon of the client with the maximum retirement age
export const PlanningHorizonDate = (clients) => {
  let [client] = clients;
  if (client?.joint === 1 && clients.length > 1) {
    client = clients.reduce((maxClient, client) => {
      return client.retirement_age > (maxClient?.retirement_age || 0) ? client : maxClient;
    }, null);
  }
  return client?.planning_horizon ? dayjs(client?.date_of_birth, 'YYYY-MM-DD').add(client?.planning_horizon, 'year') : null;
};

// Calculate the current deposit value given the opening deposit, start date, and interest rate
export const CalculateCurrentDepositValue = (openingDeposit, startDate, interestRate) => {
  const days = dayjs().diff(dayjs(startDate, 'MM/DD/YYYY'), 'day');
  return openingDeposit * (1 + (days * (interestRate / 100)) / 365);
};

export const RetirementExpenseRatio = (clients, date, retirementExpenseRatio) => {
  let [client] = clients;
  if (retirementExpenseRatio?.find((ratio) => parseInt(ratio.user_id) === parseInt(client.user_id))) {
    return retirementExpenseRatio?.find((ratio) => parseInt(ratio.user_id) === parseInt(client.user_id))?.expense_ratio;
  }
  return null;
};

const findHighestByDobAndPlaningHorizon = (array) => {
  if (array.length === 0) return null;
  return array
    .filter((item) => item.date_of_birth && item.planning_horizon !== undefined) // Filter out invalid data
    .reduce((maxItem, currentItem) => {
      const birthYear = new Date(currentItem.date_of_birth).getFullYear();
      const currentSum = birthYear + currentItem.planning_horizon;
      const maxSum = new Date(maxItem.date_of_birth).getFullYear() + maxItem.planning_horizon;
      return currentSum > maxSum ? currentItem : maxItem;
    });
};

// Calculate the start date of retirement expenses based on the client's date of birth and retirement age
export const RetirementExpenseStartDate = (clients) => {
  let [client] = clients;
  if (client.joint === 1 && clients.length > 1) {
    client = findHighestByDobAndPlaningHorizon(clients);
  }
  return client?.date_of_birth ? dayjs(client?.date_of_birth, 'YYYY-MM-DD').add(client?.retirement_age ?? 0, 'year') : null;
};

// Calculate the end date of retirement expenses based on the client's date of birth and planning horizon
export const RetirementExpenseEndDate = (clients) => {
  let [client] = clients;
  if (client.joint === 1 && clients.length > 1) {
    client = clients.reduce((maxClient, client) => {
      return client.planning_horizon > (maxClient?.planning_horizon || 0) ? client : maxClient;
    }, null);
  }
  return client?.date_of_birth ? dayjs(client.date_of_birth, 'YYYY-MM-DD').add(client.planning_horizon ?? 0, 'year') : null;
};

// Calculate the start date of social security based on the client's date of birth and retirement age
export const SocialSecurityStartDate = (clients) => {
  let [client] = clients;
  if (client?.joint === 1 && clients.length > 1) {
    client = clients?.reduce((maxClient, client) => {
      return client?.current_age > (maxClient?.current_age || 0) ? client : maxClient;
    }, null);
  }
  return client?.date_of_birth ? { limit: { min: dayjs(client?.date_of_birth, 'YYYY-MM-DD').add(client?.retirement_age, 'year') } } : null;
};

// Calculate the start date of social security based on the client's date of birth and retirement age
export const DateOfDeath = (linkToLifeRisk, _, { LinkToLifeRisks }) => {
  return LinkToLifeRisks.length
    ? LinkToLifeRisks.find(({ value }) => value === (linkToLifeRisk?.[0] ?? linkToLifeRisk))?.data?.date_of_death
    : NULLABLE_VALUE;
};
