import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getSimsCalculation } from 'store/financialTool/action';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, CircularProgress, Box, Typography } from '@mui/material';

const SimsCalcualtion = ({ financialPlanId }) => {
  const dispatch = useDispatch();
  const simsCalculationTable = useSelector((state) =>
    financialPlanId?.id && financialPlanId?.annuity_type !== '' ? state?.financialPlan?.simsCalculationTable : []
  );
  const [loading, setLoading] = useState(true);
  const portal = useSelector((state) => state.clientManagement.client_portal);
  const auth = useSelector((state) => state?.auth?.login);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      const household = portal.household_id;
      const userId = portal.household_id ? null : auth.user.id;
      const financial_plan_data_id = financialPlanId?.id;
      financial_plan_data_id &&
        financialPlanId?.annuity_type !== '' &&
        (await dispatch(getSimsCalculation(household, userId, financial_plan_data_id)));
      setTimeout(() => {
        setLoading(false);
      }, 1000);
    };
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [financialPlanId, dispatch]);

  return (
    <TableContainer component={Paper} sx={{ marginTop: '10px' }}>
      {loading ? (
        <Box display="flex" justifyContent="center" alignItems="center">
          <CircularProgress />
        </Box>
      ) : simsCalculationTable?.length === 0 ? (
        <Box display="flex" justifyContent="center" alignItems="center">
          <Typography>No data available</Typography>
        </Box>
      ) : (
        <Table>
          <TableHead>
            <TableRow>
              {['Year', 'Amount'].map((header) => (
                <TableCell key={header} sx={{ background: '#418A6E', color: '#fff', textAlign: 'center' }}>
                  {header}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {simsCalculationTable &&
              simsCalculationTable?.map((item, index) => (
                <TableRow key={index}>
                  <TableCell sx={{ whiteSpace: 'normal', textAlign: 'center' }}>{item?.year}</TableCell>
                  <TableCell sx={{ whiteSpace: 'normal', textAlign: 'center' }}>
                    {item?.initial_investment?.toLocaleString('en-US', { style: 'currency', currency: 'USD' })}
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      )}
    </TableContainer>
  );
};

export default SimsCalcualtion;
