export const GET_CATEGORIES = 'GET_CATEGORIES';
export const GET_GOALS = 'GET_GOALS';
export const GET_FINANCIAL_PLAN = 'GET_FINANCIAL_PLAN';
export const SET_FINANCIAL_PLAN = 'SET_FINANCIAL_PLAN';
export const DELETE_FINANCIAL_PLAN_DATA = 'DELETE_FINANCIAL_PLAN_DATA';
export const SET_SELECTED_USER = 'SET_SELECTED_USER';
export const RESET_FINANCIAL_PLAN = 'RESET_FINANCIAL_PLAN';
export const GET_CASHFLOW = 'GET_CASHFLOW';
export const REVERT_CASHFLOW = 'REVERT_CASHFLOW';
export const REVERT_WEALTH_CHART = 'REVREVERT_WEALTH_CHARTERT_CASHFLOW';
export const GET_PORTFOLIO_CURRENT_DATA = 'GET_PORTFOLIO_CURRENT_DATA';
export const GET_PFS_REPORT = 'GET_PFS_REPORT';
export const GET_PLAN = 'GET_PLAN';
export const GET_OPTIMIZE_DATA = 'GET_OPTIMIZE_DATA';
export const GET_CASHFLOW_TABLE_DATA = 'GET_CASHFLOW_TABLE_DATA';
export const GET_SPLASH_DATA = 'GET_SPLASH_DATA';
export const RELOAD_OPTIMIZE = 'RELOAD_OPTIMIZE';
export const GET_AMORTIZATION_TABLE_DATA = 'GET_AMORTIZATION_TABLE_DATA';
export const GET_YOUTUBE_DATA = 'GET_YOUTUBE_DATA';
export const GET_WEALTH_CHART = 'GET_WEALTH_CHART';
export const GET_WEALTH_CHART_TABLE_DATA = 'GET_WEALTH_CHART_TABLE_DATA';
export const GET_FINAL_CHART = 'GET_FINAL_CHART';
export const GET_RETIREMENT_EXPENSE_RATIO = 'GET_RETIREMENT_EXPENSE_RATIO';
export const GET_SIMS_CALCULATION = 'GET_SIMS_CALCULATION';
